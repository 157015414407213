import React, { useState, useEffect } from "react";

import { SvgClose, SvgDelete } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import './RequestBudget.css';

import TextareaAutosize from 'react-textarea-autosize';
import { Reg_RequestBudget } from "services";

export default function PopUP_RequestBudget(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ name, setName ]           = useState('');
    const [ email, setEmail ]         = useState('');
    const [ dateStart, setDateStart ] = useState('');
    const [ dateEnd, setDateEnd ]     = useState('');
    const [ phone, setPhone ]         = useState('');
    const [ text, setText ]           = useState('');
    const [ msgError, setMsgError ]   = useState('');

    const [ listData, setlistData ] = useState([]);

    function ClosePopUp(){
        setName('');
        setEmail('');
        setDateStart('');
        setDateEnd('');
        setPhone('');
        setText('');
        setMsgError('');
        setlistData([]);
        SetModalState('RequestBudget', false);
    }

    function HandleData(type, index, value){
        const newData = [...listData];
        newData[index][type] = value;
        setlistData(newData);
    }

    function saveData(event){
        event.preventDefault();
        modalData.setLoading(true);
        Reg_RequestBudget(modalData.type, listData, name, email, dateStart, dateEnd, phone, text, CallbackSuccess, CallbackError);
    }

    function CallbackSuccess(){
        modalData.setLoading(false);
        setMsgError('Mensagem enviada com sucesso, entraremos em contato com o senhor(a) o mais breve possível');
    }

    function CallbackError(){
        modalData.setLoading(false);
        setMsgError('Erro ao enviar msg, favor entrar em contato no número (75) 98204-2020');
    }

    useEffect(()=>{
        RegisterModalData('RequestBudget', setModaldata);
        RegisterModalObserver('RequestBudget', setShowPopUp);
    }, []);
    
    useEffect(()=>{
        if(showPopUp == true){
            document.body.style.overflow = "hidden";
            setlistData(modalData.data);
        }else {
            document.body.style.overflow = null;
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all request_budget">
                        <div className="div_data type_div">
                            {
                                modalData.type == "panel" ? 
                                <div className="title">Solicitar cotação dos painéis selecionados</div> :
                                <div className="title">Solicitar cotação</div>
                            }
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            {
                                msgError == "" ? 
                                <form onSubmit={ saveData } className="content">
                                    <div className="list_data_input">
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } required maxLength="140" />
                                            <div className="name_input">Nome completo*</div>
                                        </div>
                                        <div className="div_input space_div">
                                            <input type="email" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } required maxLength="140" />
                                            <div className="name_input">E-mail*</div>
                                        </div>
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ setPhone(e.target.value) } } value={ phone } required maxLength="60" />
                                            <div className="name_input">Contato*</div>
                                        </div>
                                    </div>
                                    <div className="list_data_input">
                                        <div className="div_input space_div">
                                            <TextareaAutosize className="input textarea" onChange={ (e)=>{ setText(e.target.value) } } value={ text.replace(/<br>/g,'\n') } />
                                            <div className="name_input">Descrição</div>
                                        </div>
                                    </div>
                                    {
                                        modalData.type != "panel" ? null : 
                                        <div className="list_data_input direction_column">
                                            <div className="list_title">
                                                Lista dos painéis que houve interesse
                                            </div>
                                            <div className="show_panel">
                                                {
                                                    listData.map((elem, index)=>{
                                                        return(
                                                            <div className="show_data_panel" key={ index }>
                                                                <div className="list_name_panel">
                                                                    Código: { elem.code } - Estado: { elem.state } - Cidade: { elem.city }
                                                                </div>
                                                                <div className="list_data">
                                                                    Data inicial
                                                                    <input type="date" onChange={ (e)=>{ HandleData('date_start', index, e.target.value) } } value={ elem.date_start } />
                                                                    Data término
                                                                    <input type="date" onChange={ (e)=>{ HandleData('date_end', index, e.target.value) } } value={ elem.date_end } />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className="list_data_input">
                                        <button type="submit" className="div_input space_div btn">
                                            Salvar
                                        </button>
                                    </div>
                                </form> :
                                <div className="show_msg">
                                    { msgError }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
