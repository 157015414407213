import Axios from 'axios';

// Request Budget
export function Reg_RequestBudget(type, listDate, name, email, dateStart, dateEnd, phone, text, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("current_page", "request_budget");

    data.append('type', type);
    data.append('name', name);
    data.append('email', email);
    data.append('date_start', dateStart);
    data.append('date_end', dateEnd);
    data.append('phone', phone);
    data.append('text', text);
    
    listDate.forEach(element => {
        data.append('favorite[]', element.id);
        data.append('favorite_date_start[]', element.date_start);
        data.append('favorite_date_end[]', element.date_end);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/request_budget.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        switch (response.data) {
            case "Email enviado com sucesso":
                    CallbackSuccess();
                break;
            default:
                    CallbackError();
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}

// Contact
export function Reg_Contact(name, email, phone, toKnow, text, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("current_page", 'contact');

    data.append('name', name);
    data.append('email', email);
    data.append('phone', phone);
    data.append('to_know', toKnow);
    data.append('text', text);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/contact.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        switch (response.data) {
            case "Email enviado com sucesso":
                    CallbackSuccess();
                break;
            default:
                    CallbackError();
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}

// Download file
export function Reg_DownloadFile(type, listData, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("current_page", 'download_file');

    let url = '';
    if(type == 'broadcast'){
        url = process.env.REACT_APP_API_URL + 'php/download_broadcast.php';

    }else if(type == 'panel'){
        listData.forEach(element => {
            data.append('favorite[]', element.id);
        });
        url = process.env.REACT_APP_API_URL + 'php/download_panel.php';
    }

    Axios({
        url     : url,
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}