import { useState, useEffect } from "react";

import './Contact.css';

import TextareaAutosize from 'react-textarea-autosize';

import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";
import { Reg_Contact } from "services";
import { phoneMask } from "services/mask";

export default function Page_Contact(props){

    const [ showData, setShowData ] = useState(ShowDataInitial());

    const [ name, setName ]     = useState('');
    const [ email, setEmail ]   = useState('');
    const [ phone, setPhone ]   = useState('');
    const [ toKnow, setToKnow ] = useState('');
    const [ text, setText ]     = useState('');

    function ShowDataInitial(){
        let newData = '';
        Object.keys(GetDataPage('site')).map((elem, index)=>{
            if(GetDataPage('site')[index].idPage == props.idPage){
                newData = GetDataPage('site')[index];
            }
        })
        return newData;
    } 
    
    function saveData(event){
        event.preventDefault();
        props.setLoading(true);
        if(email.includes('@') && email.includes('.')){
            Reg_Contact(name, email, phone, toKnow, text, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }else {
            EmailInvalided();
        }
    }

    function EmailInvalided(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "missingData", "text": "E-mail inválido!" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackSuccess(){
        props.setLoading(false);
        setName('');
        setEmail('');
        setPhone('');
        setToKnow('');
        setText('');
        
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Page_Contact" id="contact">
            <div className="container">
                <div className="title_page">Contato</div>
                <div className="show_data_contact">
                    <div className="div_form">
                        <form className="form" onSubmit={ saveData }>
                            <div className="wave-group">
                                <input type="text" className="input" onChange={ (e)=>{ setName(e.target.value) } } value={ name } required />
                                <span className="bar"></span>
                                <label className="label">
                                    <span className="label-char" style={ { '--index': 0 } }>N</span>
                                    <span className="label-char" style={ { '--index': 1 } }>o</span>
                                    <span className="label-char" style={ { '--index': 2 } }>m</span>
                                    <span className="label-char" style={ { '--index': 3 } }>e</span>
                                    <span className="label-char" style={ { '--index': 4, paddingLeft: "6px" } } />
                                    <span className="label-char" style={ { '--index': 5 } }>c</span>
                                    <span className="label-char" style={ { '--index': 6 } }>o</span>
                                    <span className="label-char" style={ { '--index': 7 } }>m</span>
                                    <span className="label-char" style={ { '--index': 8 } }>p</span>
                                    <span className="label-char" style={ { '--index': 9 } }>l</span>
                                    <span className="label-char" style={ { '--index': 10 } }>e</span>
                                    <span className="label-char" style={ { '--index': 11 } }>t</span>
                                    <span className="label-char" style={ { '--index': 12 } }>o</span>
                                </label>
                            </div>

                            <div className="wave-group">
                                <input type="text" className="input" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } required />
                                <span className="bar"></span>
                                <label className="label">
                                    <span className="label-char" style={ { '--index': 0 } }>E</span>
                                    <span className="label-char" style={ { '--index': 1 } }>-</span>
                                    <span className="label-char" style={ { '--index': 2 } }>m</span>
                                    <span className="label-char" style={ { '--index': 3 } }>a</span>
                                    <span className="label-char" style={ { '--index': 4 } }>i</span>
                                    <span className="label-char" style={ { '--index': 5 } }>l</span>
                                </label>
                            </div>

                            <div className="wave-group">
                                <input type="text" className="input" onChange={ (e)=>{ setPhone(e.target.value) } } value={ phoneMask(phone) } required />
                                <span className="bar"></span>
                                <label className="label">
                                    <span className="label-char" style={ { '--index': 0 } }>C</span>
                                    <span className="label-char" style={ { '--index': 1 } }>e</span>
                                    <span className="label-char" style={ { '--index': 2 } }>l</span>
                                    <span className="label-char" style={ { '--index': 3 } }>u</span>
                                    <span className="label-char" style={ { '--index': 4 } }>l</span>
                                    <span className="label-char" style={ { '--index': 5 } }>a</span>
                                    <span className="label-char" style={ { '--index': 6 } }>r</span>
                                </label>
                            </div>

                            <div className="wave-group">
                                <input type="text" className="input" onChange={ (e)=>{ setToKnow(e.target.value) } } value={ toKnow } required />
                                <span className="bar"></span>
                                <label className="label">
                                    <span className="label-char" style={ { '--index': 0 } }>C</span>
                                    <span className="label-char" style={ { '--index': 1 } }>o</span>
                                    <span className="label-char" style={ { '--index': 2 } }>m</span>
                                    <span className="label-char" style={ { '--index': 3 } }>o</span>
                                    <span className="label-char" style={ { '--index': 4, paddingLeft: "6px" } } />
                                    <span className="label-char" style={ { '--index': 5 } }>n</span>
                                    <span className="label-char" style={ { '--index': 6 } }>o</span>
                                    <span className="label-char" style={ { '--index': 7 } }>s</span>
                                    <span className="label-char" style={ { '--index': 8, paddingLeft: "6px" } } />
                                    <span className="label-char" style={ { '--index': 9 } }>c</span>
                                    <span className="label-char" style={ { '--index': 10 } }>o</span>
                                    <span className="label-char" style={ { '--index': 11 } }>n</span>
                                    <span className="label-char" style={ { '--index': 12 } }>h</span>
                                    <span className="label-char" style={ { '--index': 13 } }>e</span>
                                    <span className="label-char" style={ { '--index': 14 } }>c</span>
                                    <span className="label-char" style={ { '--index': 15 } }>e</span>
                                    <span className="label-char" style={ { '--index': 16 } }>u</span>
                                    <span className="label-char" style={ { '--index': 17 } }>?</span>
                                </label>
                            </div>

                            <div className="wave-group">
                                <TextareaAutosize className="input textarea" onChange={ (e)=>{ setText(e.target.value) } } value={ text.replace(/<br>/g,'\n') } required />
                                <span className="bar"></span>
                                <label className="label">
                                    <span className="label-char" style={ { '--index': 0 } }>M</span>
                                    <span className="label-char" style={ { '--index': 1 } }>e</span>
                                    <span className="label-char" style={ { '--index': 2 } }>n</span>
                                    <span className="label-char" style={ { '--index': 3 } }>s</span>
                                    <span className="label-char" style={ { '--index': 4 } }>a</span>
                                    <span className="label-char" style={ { '--index': 5 } }>g</span>
                                    <span className="label-char" style={ { '--index': 6 } }>e</span>
                                    <span className="label-char" style={ { '--index': 7 } }>m</span>
                                </label>
                            </div>

                            <div className="div_btn">
                                <button type="submit" className="btn">Enviar</button>
                            </div>
                        </form>
                    </div>

                    <div className="data_text">
                        <div className="email">E-mail: { showData.title }</div>
                        <div className="text" dangerouslySetInnerHTML={ { __html: showData.text.replaceAll('&#34;', '"') } } />

                        <div className="div_icons">
                            {
                                showData.link_linkedin == "" ? null : 
                                <a href={ showData.link_linkedin } target="_blank">
                                    <img alt="linkdin" src="./assets/contact_linkdin.png" className="img" />
                                </a>
                            }
                            {
                                showData.link_instagram == "" ? null : 
                                <a href={ showData.link_instagram } target="_blank">
                                    <img alt="instagram" src="./assets/contact_instagram.png" className="img" />
                                </a>
                            }
                            {
                                showData.link_facebook == "" ? null : 
                                <a href={ showData.link_facebook } target="_blank">
                                    <img alt="facebook" src="./assets/contact_facebook.png" className="img" />
                                </a>
                            }
                            {
                                showData.link_whatsapp == "" ? null : 
                                <a href={ showData.link_whatsapp } target="_blank">
                                    <img alt="whatsapp" src="./assets/contact_whatsapp.png" className="img" />
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}