import { useState, useEffect } from "react";

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";

import Menu from "components/Menu";

import Page_About from "components/Page/About";
import Page_Client from "components/Page/Client";
import Page_Contact from "components/Page/Contact";
import Page_Product from "components/Page/Product";

import PopUP_Redirect from "components/PopUp/Redirect";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

import Page_Home from "components/Page/Home";

import Page_Panel from "components/Page/Panel";
import Page_Broadcast from "components/Page/Broadcast";

import { SvgArrow_3 } from "components/SvgFile";
import PopUP_ShowFile from "components/PopUp/ShowFile";

export default function App() {
    const [ loading, setLoading ]         = useState(false);
    const [ showData, setShowData ]       = useState(GetDataPage('name_menu'));
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const url      = new URLSearchParams(window.location.search);
    const showLink = url.get("panel");

    function CkickPage(value){      
        switch (value) {
            case "index":
                    SetListPag('currentPage', value);   
                break;
        
            default: 
                    SetListPag('remuve', 'All');
                    SetListPag('currentPage', 'index');   
                break;
        }
    }

    function CurrentPage(){
        let nameMenu = 'index';
        if(showLink){
            let typeLink = showLink.split('/');    
            if(typeLink[0] == "listFavorite"){
                nameMenu = "favorite";
            }else { 
                nameMenu = currentPage;
            }
        }else {
            nameMenu = currentPage;
        }

        switch (nameMenu) {
            case "favorite":
                let typeLink = showLink.split('/');
                return <Page_Panel CkickPage={ CkickPage } setLoading={ setLoading } listFavorite={ typeLink[1] } />;

            case "panel":
                return <Page_Panel CkickPage={ CkickPage } setLoading={ setLoading } listFavorite="" />;

            case "broadcast":
                return <Page_Broadcast CkickPage={ CkickPage } setLoading={ setLoading } />;

            default:
                return(
                    Object.keys(showData).map((elem, index)=>{
                        if(showData[index].id != 4 && showData[index].id != 5 && showData[index].id != 8 && showData[index].id != 9){
                            return(
                                <div className="show_data_page" id={ showData[index].page } key={ index }>
                                    { ShowDataPage(showData[index].id, index) }
                                </div>
                            )
                        }
                    })
                )
        }
    }

    function ShowDataPage(value, index){
        switch (value) {
            case 1:
                return <Page_Home CkickPage={ CkickPage } setLoading={ setLoading } idPage={ value } index={ index } />;

            case 2:
                return <Page_About CkickPage={ CkickPage } setLoading={ setLoading } idPage={ value } index={ index } />;

            case 3:
                return <Page_Product CkickPage={ CkickPage } setLoading={ setLoading } idPage={ value } index={ index } />;

            case 6:
                return <Page_Client CkickPage={ CkickPage } setLoading={ setLoading } idPage={ value } index={ index } />;

            // case 7:
            //     return <Page_Contact CkickPage={ CkickPage } setLoading={ setLoading } idPage={ value } />;
        }
    }

    function ReturnIndex(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        if(showLink){
            let typeLink = showLink.split('/');    
            if(typeLink[0] == "listFavorite"){
                SetListPag('currentPage', 'panel');   
            }
        }
        
        window.addEventListener("scroll", ()=>{
            if(window.scrollY >= 60){
                document.getElementById('return').classList.add('return_index');
                document.getElementById('return').classList.remove('return_close');
            }else {
                document.getElementById('return').classList.add('return_close');
                document.getElementById('return').classList.remove('return_index');
            }
        });
    }, []);

    useEffect(()=>{
        setCurrentPage(GetListPag('currentPage'));
    }, [currentPage]);

    useEffect(()=>{
        if(loading == true){
            document.body.style.overflow = "hidden";
        }else {
            document.body.style.overflow = null;
        }
    }, [loading]);

    return (
        <>
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                 <div className="hourglassBackground">
                    <div className="hourglassContainer">
                        <div className="hourglassCurves" />
                        <div className="hourglassCapTop" />
                        <div className="hourglassGlassTop" />
                        <div className="hourglassSand" />
                        <div className="hourglassSandStream" />
                        <div className="hourglassCapBottom" />
                        <div className="hourglassGlass" />
                    </div>
                </div>
            </div>
            
            <div className="whatsapp">
                <a href="" target="_blank">
                    <img alt="Whatsapp" src="./assets/whatsapp.svg" className="icon_whatsapp" />
                </a>
            </div>

            <div className="return_close" id="return" onClick={ ()=>{ ReturnIndex() } }>
                <SvgArrow_3 className="icon_whatsapp" color="rgb(255 181 0)" />
            </div>

            <Menu CkickPage={ CkickPage } setLoading={ setLoading } />

            {
                CurrentPage()
            }

            <Page_Contact CkickPage={ CkickPage } setLoading={ setLoading } idPage={ 7 } />

            <PopUP_Redirect />
            <PopUP_ShowFile />
            <PopUP_ReturnResponse />
        </>
    );
}