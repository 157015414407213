
let DataPage = {
    "name_menu" : {},
    "site"      : {},
    "product"   : {},
    "panel"     : {},
    "broadcast" : {},

    "favorite" : []
};

let NotifyDataPage = {
    "name_menu" : [],
    "site"      : [],
    "product"   : [],
    "panel"     : [],
    "broadcast" : [],

    "favorite" : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["name_menu"].forEach(element => {
        element(value['name_menu']);
    });
    NotifyDataPage["site"].forEach(element => {
        element(value['site']);
    });
    NotifyDataPage["product"].forEach(element => {
        element(value['product']);
    });
    NotifyDataPage["panel"].forEach(element => {
        element(value['panel']);
    });
    NotifyDataPage["broadcast"].forEach(element => {
        element(value['broadcast']);
    });
    NotifyDataPage["favorite"].forEach(element => {
        element(value['favorite']);
    });
}

export function SetListDataSingle(key, value) {
    
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
