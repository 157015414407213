import { useState, useEffect } from "react";

import './Panel.css';

import Select from 'react-select';


import { SvgStar_1, SvgStar_2 } from "components/SvgFile";

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";
import PopUP_RequestBudget from "components/PopUp/RequestBudget";
import { Reg_DownloadFile } from "services";

export default function Page_Panel(props){

    const url      = new URLSearchParams(window.location.search);
    const showLink = url.get("panel");

    const [ type, setType ]   = useState('Selecione uma opção');
    const [ toast, setToast ] = useState(false);

    const [ currentPage, setCurrentPage ]   = useState(GetListPag('currentPage'));
    const [ searchType, setSearchType ]     = useState(GetListPag('search'));
    const [ showData, setShowData ]         = useState(InitialData());
    const [ listFavorite, setListFavorite ] = useState([]);

    const [ status, setStatus ] = useState(true);

    const [ code, setCode ]     = useState('');
    const [ search, setSearch ] = useState(false);

    const [ listCity, setListCity ] = useState(ShowCity());
    const [ city, setCity ]         = useState('Selecione uma opção');

    const [ listNeighborhood, setListNeighborhood ] = useState(ShowNeighborhood());
    const [ neighborhood, setNeighborhood ]         = useState('Selecione uma opção');

    const [ listType, setListType ] = useState(ShowType());

    function InitialData(){
        if(showLink){
            let typeLink = showLink.split('/');    
            if(typeLink[0] == "listFavorite"){
                const listData = typeLink[1].split(',');
                const newData  = [];
                listData.map((elem, index)=>{
                    newData.push(GetDataPage('panel').find(item => item.id == elem));
                })

                newData.map((elem, index)=>{
                    elem.status = true;
                })
                return newData;
            }else { 
                return GetDataPage('panel');
            }            
        }else {
            return GetDataPage('panel');
        }        
    }

    function ShowType(){
        const options = [{ "value": "Selecione uma opção", "label": "Selecione uma opção" }];
        GetDataPage('panel').map((elem, index)=>{
            if(options.find(item => item.value == elem.type_name)){ }else{
                options.push({
                    "value": elem.type_name, "label": elem.type_name
                });
            }
        });
        return options;
    }

    function ShowCity(){
        const options = [{ "value": "Selecione uma opção", "label": "Selecione uma opção" }];
        GetDataPage('panel').map((elem, index)=>{
            if(options.find(item => item.value == elem.city)){ }else{
                options.push({
                    "value": elem.city, "label": elem.city
                });
            }
        });
        return options;
    }

    function ShowNeighborhood(){
        const options = [{ "value": "Selecione uma opção", "label": "Selecione uma opção" }];
        GetDataPage('panel').map((elem, index)=>{
            if(elem.city == city){
                if(elem.neighborhood !=''){
                    if(options.find(item => item.value == elem.neighborhood)){ }else{
                        options.push({
                            "value": elem.neighborhood, "label": elem.neighborhood
                        });
                    }
                }
            }
        });
        return options;
    }
    
    function SearchCode(value){
        setCode(value);
        if(value){
            setSearch(true);
            const newList = [];
            if(code != ""){
                GetDataPage('panel').map((elem, index)=>{
                    if(elem.code.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(elem);
                    }
                });
            }
            setShowData(newList);
        }else {
            setShowData(GetDataPage('panel'));
        }
    }

    function Search(value){
        window.history.pushState("", "", "/");
        if(value == 'search'){
            setSearch(true);
            const newList = [];
            if(type !='Selecione uma opção'){
                if(city !='Selecione uma opção'){
                    if(neighborhood !='Selecione uma opção'){
                        GetDataPage('panel').map((elem, index)=>{
                            if(elem.type_name == type){
                                if(elem.city == city){
                                    if(elem.neighborhood == neighborhood){
                                        newList.push(elem.id);
                                    }
                                }
                            }
                        });

                    }else {   
                        GetDataPage('panel').map((elem, index)=>{
                            if(elem.type_name == type){
                                if(elem.city == city){
                                    newList.push(elem.id);
                                }
                            }
                        });                     
                    }
                }else {
                    GetDataPage('panel').map((elem, index)=>{
                        if(elem.type_name == type){
                            newList.push(elem.id);
                        }
                    });
                }

            }else {
                if(city !='Selecione uma opção'){
                    if(neighborhood !='Selecione uma opção'){
                        GetDataPage('panel').map((elem, index)=>{
                            if(elem.city == city){
                                if(elem.neighborhood == neighborhood){
                                    newList.push(elem.id);
                                }
                            }
                        });
                    }else { 
                        GetDataPage('panel').map((elem, index)=>{
                            if(elem.city == city){
                                newList.push(elem.id);
                            }
                        });                       
                    }
                }else {
                    GetDataPage('panel').map((elem, index)=>{
                        newList.push(elem.id);
                    });   
                }
            }

            if(neighborhood !='Selecione uma opção'){
                GetDataPage('panel').map((elem, index)=>{
                    elem.coverage.map((elem_1, index_1)=>{
                        if(elem_1.name == neighborhood){
                            if(newList.find(item => item.id == elem.id)){ }else {
                                newList.push(elem.id);
                            }
                        }
                    })
                })
            }                                    
            
            const newData = [];
            newList.map((elem, index)=>{
                newData.push(GetDataPage('panel').find(item => item.id == elem));
            })
            setShowData(newData);
            SetListPag('search', '');

        }else if(value == 'type'){
            setSearch(true);
            const newList = [];
            GetDataPage('panel').map((elem, index)=>{
                if(elem.type_name == GetListPag('search')){
                    newList.push(elem);
                }
            });
            SetListPag('search', '');
            setShowData(newList);

        }else if(value == 'trash'){
            setSearch(false);
            setType('Selecione uma opção');
            setCity('Selecione uma opção');
            setNeighborhood('Selecione uma opção');
            SetListPag('search', '');

            setShowData(GetDataPage('panel'));
        }
    }

    function AddFavorite(id, code, state, city, index, status){
        const newData = [...showData];
        newData[index].status = status;
        setShowData(newData);

        const newDataFavorite = [...listFavorite];        
        if(status == true){
            if(newDataFavorite.find(item => item.id == id)){ }else {
                newDataFavorite.push({ "id": id, "code": code, "state": state, "city": city, "date_start": "", "date_end": "" });
            }
        }else {
            let indexFavorite = newDataFavorite.map(item => item.id).indexOf(id);
            newDataFavorite.splice(indexFavorite, 1);
        }
        setListFavorite(newDataFavorite);
    }

    function OpenDetails(data){
        SetModalData('ShowFile', { data });
        SetModalState('ShowFile', true);
    }

    function SolicitedBudget(){
        SetModalData('RequestBudget', { "type": "panel", "data": listFavorite, "setLoading": props.setLoading });
        SetModalState('RequestBudget', true);
    } 

    function GenerateLink(){
        const newData = [];
        listFavorite.map((elem, index)=>{
            newData.push(elem.id);
        });

        setToast(true);
        navigator.clipboard.writeText(process.env.REACT_APP_API_URL + "?panel=listFavorite/" + newData);
        setTimeout(() => {
            setToast(false);
        }, 1500);

        SetModalData('ReturnResponse', { "page": "missingData", "text": `Link dos favoritos gerado com sucesso, basta "colar" no local desejado. Para mais informações entre em contato no número (75) 98204-2020` });
        SetModalState('ReturnResponse', true);
    }

    function DownloadFile(){
        const newData = [];
        listFavorite.map((elem, index)=>{
            newData.push(elem.id);
        });
        props.setLoading(true);
        Reg_DownloadFile("panel", listFavorite, CallbackSuccess, CallbackError);        
        window.location.href = process.env.REACT_APP_API_URL + 'php/download_panel.php?listPanel=' + newData;
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "missingData", "text": "Download concluído com sucesso, para mais informações entre em contato no número (75) 98204-2020" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "Erro ao realizar o download, tente novamente! Caso o erro persistir favor entrar em contato no número (75) 98204-2020" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{        
        const showType = setInterval(() => {
            if(searchType !=''){
                setType(GetListPag('search'));
                Search('type');                
                setSearch(true);

                clearInterval(showType);
            }
        }, 100);
    }, [searchType]);

    useEffect(()=>{
        if(showLink){
            setSearch(true);
        }else {
            if(currentPage != "panel"){
                setShowData(GetDataPage('panel'));
                setStatus(true);
            }
        }
    }, [currentPage]);

    useEffect(()=>{
        setCity('Selecione uma opção');
        setType('Selecione uma opção');
        setNeighborhood('Selecione uma opção');
    }, [code]);

    useEffect(()=>{
        setCode('');
    }, [type, city, neighborhood]);

    useEffect(()=>{
        setListNeighborhood(ShowNeighborhood());
    }, [city]);

    return(
        <div className="Page_Panel" id="panel">
            <div className="container ">
                <div className="list_data_search">
                    <div className="list_opt_state">
                        <div className="show_opt_search">
                            <div className="">Código</div>
                            <div className="">
                                <input type="text" className="input_code" onChange={ (e)=>{ SearchCode(e.target.value) } } value={ code } maxLength={ 4 } placeholder="####" />
                            </div>
                        </div>

                        <div className="show_opt_search">
                            <div className="">Tipo</div>
                            <div className="">
                                <Select className="select_city" defaultOptions options={ listType } value={ { "value": type, "label": type } } onChange={ (e)=> { setType(e.value); } } placeholder="..." />
                            </div>
                        </div>

                        <div className="show_opt_search">
                            <div className="">Cidades</div>
                            <div className="">
                                <Select className="select_city" defaultOptions options={ listCity } value={ { "value": city, "label": city } } onChange={ (e)=> { setCity(e.value); } } placeholder="..." />
                            </div>
                        </div>

                        <div className="show_opt_search">
                            <div className="">Bairro</div>
                            <div className="">
                                <Select className="select_city" defaultOptions options={ listNeighborhood } value={ { "value": neighborhood, "label": neighborhood } } onChange={ (e)=> { setNeighborhood(e.value); } } placeholder="..." />
                            </div>
                        </div>
                    </div>
                    {
                        code == "" && type == "Selecione uma opção" && city == "Selecione uma opção" && neighborhood == "Selecione uma opção" && search == false ? null :
                        <div className="list_opt_state list_opt_state_btn">
                            <div className="btn" onClick={ ()=>{ Search('search') } }>
                                Pesquisar
                            </div>
                            {
                                search == false ? null :
                                <div className="btn trash" onClick={ ()=>{ Search('trash') } }>
                                    Limpar pesquisa
                                </div>
                            }
                        </div>
                    }
                    {
                        listFavorite.length == 0 ? null :
                        <div className="opt_file">
                            <div className="color_1" />
                            <div className="btn btn_file" onClick={ ()=>{ GenerateLink() } }>
                                Gerar link
                            </div>
                            <div className="color_2" />
                            <div className="btn btn_link" onClick={ ()=>{ DownloadFile() } }>
                                Download dos favoritos
                            </div>
                            <div className="color_3" />
                            <div className="btn btn_file" onClick={ ()=>{ SolicitedBudget() } }>
                                Solicitar cotação dos favoritos
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="list_panel container">
                {
                    showData.map((elem, index)=>{
                        return(
                            <div className="show_panel" key={ index }>
                                <div className="div_img" onClick={ ()=>{ OpenDetails(elem) } }>
                                    <div className="img_">
                                        <img alt="img" src={ elem.file } className="img_panel" loading="lazy" />
                                    </div>
                                    <div className="city">{ elem.city }</div>
                                </div>

                                <div className="data_project">
                                    <div className="text">
                                        <b>Código:</b> { elem.code }
                                    </div>
                                    <div className="text" onClick={ ()=>{ AddFavorite(elem.id, elem.code, elem.state, elem.city, index, !elem.status) } }>
                                        {
                                            elem.status ? 
                                            <SvgStar_2 className="icons" color="#002d6a" /> :
                                            <SvgStar_1 className="icons" color="#002d6a" />
                                        }
                                    </div>
                                </div>
                                <div className="data_project address">
                                    <div className="text">
                                        <b>Endereço:</b> { elem.address }
                                    </div>
                                </div>
                                <div className="data_project details" onClick={ ()=>{ OpenDetails(elem) } }>
                                    <div className="text">
                                        Mais detalhes
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <PopUP_RequestBudget />
            
            {
                status ? 
                <div className="inf_favorite">
                    <div className="text_favorite">
                        Clique <SvgStar_1 className="icons" color="#002d6a" /> para adicionar seus favoritos
                    </div>
                    <div className="btn_favorite" onClick={ ()=>{ setStatus(false) } }>
                        OK
                    </div>
                </div> : null
            }
        </div>
    )
}