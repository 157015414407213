import { useState, useEffect } from "react";

import './Product.css';

import { SetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";

export default function Page_Product(props){

    const [ showData, setShowData ] = useState(GetDataPage('product'));

    function ShowType(type){
        if(type !=''){
            window.scrollTo({ top: 0, behavior: 'smooth' });
            SetListPag('search', type);
            SetListPag('currentPage', "panel");
        }
    }
    
    return(
        <div className="Page_Product" id="product">
            <div className="container">                
                <div className="title_page">Produtos</div>
                <div className="list_product">
                    {
                        Object.keys(showData).map((elem, index)=>{
                            return(
                                <div className="show_product" key={ index } onClick={ ()=>{ ShowType(showData[index].type) } }>
                                    <div className="title">{ showData[index].title }</div>
                                    <div className="div_img">
                                        <img alt={ "img_" + index } src={ showData[index].file } className="img" loading="lazy" />
                                    </div>
                                    <div className="text" dangerouslySetInnerHTML={ { __html: showData[index].text.replaceAll('&#34;', '"') } } />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}