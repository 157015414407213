import { useState, useEffect, useRef } from "react";

import './Client.css';

import { GetDataPage } from "interface/Data";
import { SvgArrow } from "components/SvgFile";

export default function Page_Client(props){

    const carousel                  = useRef(null);
    const [ showData, setShowData ] = useState(ShowDataInitial());

    function ShowDataInitial(){
        let newData = '';
        Object.keys(GetDataPage('site')).map((elem, index)=>{
            if(GetDataPage('site')[index].idPage == props.idPage){
                newData = GetDataPage('site')[index];
            }
        })
        return newData;
    } 

    function HandleLeftClick(e){
        e.preventDefault();
        carousel.current.scrollLeft -= carousel.current.offsetWidth;
    }

    function HandleRightClick(e){
        e.preventDefault();
        carousel.current.scrollLeft += carousel.current.offsetWidth;
    }  

    return(
        <div className="Page_Client" id="client">
            <div className="container">                
                <div className="title_page">Clientes</div>
                <div className="list_clients">
                    
                    <div className="div_arrow">
                        <div className="arrow" onClick={ (e)=>{ HandleLeftClick(e) } }>
                            <SvgArrow className="icon icon_left" color="#ffb500" />
                        </div>
                        <div className="arrow" onClick={ (e)=>{ HandleRightClick(e) } }>
                            <SvgArrow className="icon" color="#ffb500" />
                        </div>
                    </div> 
                   
                    <div className="carousel" ref={ carousel }>
                        {
                            showData.contents.map((elem, index)=>{
                                return(
                                    <div className="logotipos" key={ index }>
                                        <img alt="client" src={ elem.file } className="logo" />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}