import { useState, useEffect } from "react";

import './Broadcast.css';

import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

import { Reg_DownloadFile } from "services";

import PopUP_RequestBudget from "components/PopUp/RequestBudget";

export default function Page_Broadcast(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('broadcast'));

    const [ listDate, setListDate ] = useState([]); 

    function SolicitedBudget(){
        SetModalData('RequestBudget', { "type": "broadcast", "data": listDate, "setLoading": props.setLoading });
        SetModalState('RequestBudget', true);
    }

    function DownloadFile(){
        props.setLoading(true);
        Reg_DownloadFile("broadcast", '', CallbackSuccess, CallbackError);        
        window.location.href = process.env.REACT_APP_API_URL + 'php/download_broadcast.php';
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "missingData", "text": "Download concluído com sucesso, para mais informações entre em contato no número (75) 98204-2020" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "Erro ao realizar o download, tente novamente! Caso o erro persistir favor entrar em contato no número (75) 98204-2020" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Page_Broadcast" id="broadcast">
            <div className="div_broadcast container">
                <div className="title">
                    { dataPage.title }
                </div>
                <div className="list_data_week">
                    <div className="opt_file">                      
                        <div className="color_1" onClick={ ()=>{ SolicitedBudget() } } />
                        <div className="btn btn_link" onClick={ ()=>{ SolicitedBudget() } }>
                            Solicitar cotação
                        </div>
                        <div className="color_2" />
                        <div className="btn btn_file" onClick={ ()=>{ DownloadFile() } }>
                            Download do arquivo
                        </div>
                    </div>
                    <div className="div_img" style={ { backgroundImage: 'url("' + dataPage.file + '")' } }>
                        <div className="div_logotipo">
                            <img alt="logo" src="./assets/logo_1.png" className="logotipo" />
                        </div>
                        <div className="subtitle">Bi-semanas</div>
                        <div className="pdf_title">{ dataPage.year }</div>
                    </div>

                    <div className="div_week">
                        <div className="div_list_week">
                            <div className="show_week">
                                <table width="100%" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td className="week_type title">Bi-semana</td>
                                            <td className="week_show title">Veiculação</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        {
                                            dataPage.list_week.map((elem, index)=>{
                                                if(index <= 12){
                                                    return(
                                                        <tr key={ index }>
                                                            <td className="week_type" align="center">
                                                                <div className="color_week">{ elem.week }</div>
                                                            </td>
                                                            <td className="week_show">
                                                                <div className="color_date">
                                                                    { elem.date_start_br } à { elem.date_end_br }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="show_week">
                                <table width="100%" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td className="week_type title">Bi-semana</td>
                                            <td className="week_show title">Veiculação</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        {
                                            dataPage.list_week.map((elem, index)=>{
                                                if(index > 12){
                                                    return(
                                                        <tr key={ index }>
                                                            <td className="week_type" align="center">
                                                                <div className="color_week">{ elem.week }</div>
                                                            </td>
                                                            <td className="week_show">
                                                                <div className="color_date">
                                                                    { elem.date_start_br } à { elem.date_end_br }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="div_contact">
                        <div className="title">Contato</div>
                        <div className="div_data">
                            <div className="show_data_contact">
                                <div className="div_icon">
                                    <img alt="phone" src="./assets/phone.png" className="img_icon" />
                                </div>
                                <div className="div_name">(75) 98204-2020</div>
                            </div>
                            <div className="show_data_contact">
                                <div className="div_icon">
                                    <img alt="phone" src="./assets/whatsapp.png" className="img_icon" />
                                </div>
                                <div className="div_name">(71) 99172-9849</div>
                            </div>
                        </div>
                        <div className="div_data">
                            <div className="show_data_contact">
                                <div className="div_icon">
                                    <img alt="phone" src="./assets/email.png" className="img_icon" />
                                </div>
                                <div className="div_name">contato@maxmidiaooh.com.br</div>
                            </div>
                            <div className="show_data_contact">
                                <div className="div_icon">
                                    <img alt="phone" src="./assets/site.png" className="img_icon" />
                                </div>
                                <div className="div_name">
                                    <a href="https://maxmidiaooh.com.br/" target="_blank">www.maxmidiaooh.com.br</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PopUP_RequestBudget />
        </div>
    )
}