import { useState, useEffect } from "react";

import './About.css';

import { GetDataPage } from "interface/Data";

export default function Page_About(props){

    const [ showData, setShowData ] = useState(ShowDataInitial());

    const [ listNumber, setListNumber ]     = useState(InitialData(1));
    const [ listCity, setListCity ]         = useState(InitialData(2));
    const [ listCityName, setListCityname ] = useState(InitialData(3));
    const [ listPercent, setListPercent ]   = useState(InitialData(4));

    function ShowDataInitial(){
        let newData = '';
        Object.keys(GetDataPage('site')).map((elem, index)=>{
            if(GetDataPage('site')[index].idPage == props.idPage){
                newData = GetDataPage('site')[index];
            }
        })
        return newData;
    }
    
    function InitialData(value){
        const newData = showData.contents.filter(item => item.type == value);
        return newData;
    }

    return(
        <div className="Page_About" id="about">
            <div className="show_data_about container">
                <div className="title_page">{ showData.title }</div>
                <div className="show_data">
                    <div className="div_text">
                        <div className="text" dangerouslySetInnerHTML={ { __html: showData.text.replaceAll('&#34;', '"') } } />
                    </div>
                    <div className="div_img">
                        <img alt="img" src={ showData.file } className="img" />
                    </div>
                </div>
            </div>

            <div className="show_data_number">
                <div className="container">
                    {
                        listNumber.map((elem, index)=>{
                            return(
                                <div className="data_number" key={ index }>
                                    <div className="number">{ elem.number }</div>
                                    <div className="title">{ elem.title }</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className="show_data_city container">
                <div className="title_page">{ listCity[0].title }</div>
                <div className="show_data">
                    <div className="div_map">
                        <img alt="img" src={ listCity[0].file } className="img" />
                    </div>
                    <div className="div_text">
                        {
                            listCityName.map((elem, index)=>{
                                return(
                                    <div className="data_city" key={ index }>
                                        <div className="icon">
                                            <img alt="img" src={ elem.file } className="img" />
                                        </div>
                                        <div className="title">{ elem.title }</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="show_data_percent">
                <div className="container">
                    {
                        listPercent.map((elem, index)=>{
                            return(
                                <div className="data_percent" key={ index }>
                                    <div className="number">{ elem.number }</div>
                                    <div className="title">
                                        <div className="name_title">
                                            { elem.title }
                                        </div>
                                        <div className="subtitle">
                                            { elem.subtitle }
                                        </div>                                        
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}