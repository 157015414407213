import React, { useState, useEffect } from "react";

import './ReturnResponse.css';

import { SvgChekedReturn, SvgErro } from "components/SvgFile";
import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

export default function PopUP_ReturnResponse(){

    const [ modalData, setModaldata ]     = useState({});
    const [ showPopUp, setShowPopUp ]     = useState(false);

    useEffect(()=>{
        RegisterModalData('ReturnResponse', setModaldata);
        RegisterModalObserver('ReturnResponse', setShowPopUp);
    }, []);

    function ClosePopUp(){
        SetModalState('ReturnResponse', false);
    }

    function TypeData(){
        switch (modalData.page) {
            case 'missingData':
                return(
                    <>
                        <SvgChekedReturn color="#ffb500" className="icons_return" />
                        <div className="title_return">
                            { modalData.text }
                        </div>
                    </>
                )

            case 'erro':
                return(
                    <>
                        <SvgErro color="#F00000" className="icons_return" />
                        <div className="title_return">
                            {
                                modalData.text == "" ? "Erro ao enviar msg, favor entrar em contato no número (75) 98204-2020" : modalData.text
                            }                            
                        </div>
                    </>
                )

            default:
                return(
                    <>
                        <SvgChekedReturn color="#002765" className="icons_return" />
                        <div className="title_return">
                            Mensagem enviada com sucesso, entraremos em contato com o senhor(a) o mais breve possível
                        </div>
                    </>
                )
        }
    }

    return(
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all ReturnResponse">
                        <div className="div_data">
                            <div className="content">
                                {
                                    TypeData()
                                }
                                <div className="close_return" onClick={ ()=>{ ClosePopUp() } }>
                                    Fechar
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    )
};
